<template>
    <div>
        <slot v-if="loading">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>

        <div class="market-profile-mobile-pages" v-if="!loading && localCryptoExchangesProfile">
            <div class="market-symbol-row">
                <div class="market-symbol-row-right back-profile-page">
                    <router-link class="mobile-page-header-icon pointer back-icon" :to="{ name: 'LocalExchanges', params: { type: 'sell' },}">
                        <i class="header-uil-icon uil uil-angle-right-b"></i>
                    </router-link>
                    <div class="market-symbol-avatar" :style="avatarFinder"></div>
                    <div class="market-symbol-info">
                        <h1 class="market-symbol-title">{{localCryptoExchangesProfile.category_title}}</h1>
                        <span class="market-symbol-title-en">
                            تعداد ارز : 
                            <span class="market-symbol-market">{{localCryptoExchangesProfile.items.length}}</span>
                        </span>
                    </div>
                </div>
                <div class="market-symbol-row-left">
                    <div class="market-symbol-info">
                        <!-- <div class="market-symbol-price">
                            <span class="price" data-col="info.last_trade.PDrCotVal">3</span>
                        </div>
                        <span class="market-symbol-change">
                            <span class="change-tag" data-col="info.last_trade.last_change_percentage">
                                <span class="market_header_summary.yesterday_dt.plain">4</span>
                            </span>
                        </span> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="market-profile-inner" v-if="!loading && localCryptoExchangesProfile">
            <ul>
                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">امکانات</div>
                        <div class="profile-inner-table-value"></div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">مدل کسب و کار</div>
                        <div class="profile-inner-table-value"> {{ finder(localCryptoExchangesProfile.supplier.model)}}</div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">پشتیبانی از شبکه TRC20</div>
                        <div class="profile-inner-table-value">
                            <i class="fa fa-check icon-h-i i-high" v-if="localCryptoExchangesProfile.supplier.has_trc20"></i>
                            <i class="fa fa-times icon-h-i i-low" v-else></i>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">کیف پول اختصاصی</div>
                        <div class="profile-inner-table-value">
                            <i class="fa fa-check icon-h-i i-high" v-if="localCryptoExchangesProfile.supplier.has_wallet"></i>
                            <i class="fa fa-times icon-h-i i-low" v-else></i>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">کارمزد معاملات</div>
                        <div class="profile-inner-table-value">
                            {{ localCryptoExchangesProfile.supplier.commission}}
                        </div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">اپلیکیشن‌ها</div>
                        <div class="profile-inner-table-value">
                            {{ finder(localCryptoExchangesProfile.supplier.apps) }}
                        </div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">ورود دو مرحله‌ای</div>
                        <div class="profile-inner-table-value">
                            <i class="fa fa-check icon-h-i i-high" v-if="localCryptoExchangesProfile.supplier.has_two_factor"></i>
                            <i class="fa fa-times icon-h-i i-low" v-else></i>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">پشتیبانی</div>
                        <div class="profile-inner-table-value">
                            {{ finder(localCryptoExchangesProfile.supplier.support) }}
                        </div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">زمان احراز هویت</div>
                        <div class="profile-inner-table-value">
                            {{ localCryptoExchangesProfile.supplier.confirmation}}
                        </div>
                    </div>
                </li>
                <li style="border: 0 !important;">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">پایه پولی</div>
                        <div class="profile-inner-table-value">
                            {{ finder(localCryptoExchangesProfile.supplier.currency) }}
                        </div>
                    </div>
                </li>
                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">ارزها</div>
                        <div class="profile-inner-table-value"></div>
                    </div>
                    <div class="header-actions-sw">
                        <div :class="'header-actions-sw-item ' + (type == 'sell' ? 'sw-item-active' : '')" @click="updateType('sell')">فروش</div>
                        <div :class="'header-actions-sw-item ' + (type == 'buy' ? 'sw-item-active' : '')" @click="updateType('buy')">خرید</div>
                    </div>
                </li>
            </ul>
        </div>

        <slot v-if="!loading && localCryptoExchangesProfile">
            <div v-for="(item, index) in localCryptoExchangesProfile.items" :key="index" class="market-main-item " v-if="item.title.includes('-'+type)">

                <!-- <router-link class="market-main-item-row r-low "  :to="{ name: 'MarketProfile', params: { name: item.item_id },}"> -->
                <div class="market-main-item-row r-low ">
                    <div class="market-main-item-col-logo">
                        <img :src="'https://static.tgju.org/images/crypto/assets/'+ item.title.replace('-'+type, '')+'.png'" :alt="item.category_title">
                    </div>
                    <div class="market-main-item-col item-col-right">
                        <div class="market-main-item-title">
                            <h2>{{ findCryptoName(item) }}</h2>
                        </div>
                        <div class="market-main-item-time"><i class="uil uil-clock"></i><span>{{ to_yyyy_mm_dd(item.updated_at) }}</span></div>
                    </div>
                    <div class="market-main-item-col item-col-left">
                        <div class="market-main-item-price"><span>{{ formatPrice(item.p) }}</span><div class="item-price-badge">ریال</div></div>
                        <div :class="'market-main-item-change ' +  item.dt"> {{ formatPrice(item.d) }} ({{ item.dp }} %) </div>
                    </div>
                </div>
            </div>
        </slot>
    </div>
</template>
<script>
// import NewsComments from '@/views/TgjuPostsMobile.vue'
import moment from "moment-jalaali";

export default {
    name: 'LocalCryptoExchangesProfileView',
    component: {
        // NewsComments,
    },
    data: function () {
        return {
            localCryptoExchangesProfile : {},
            cryptoName : {},
            type: 'sell',
            reload : 1,
            loading:false,
            supplier : {
                    "chat" : "چت",
                    "phone" : "تلفن",
                    "email" : "ایمیل",
                    "ticket" : "تیکت",
                    "android" : "اندرويد",
                    "exchange" : "صرافی",
                    "platform" : "بازار مبادلاتی",
                    "irt" : "تومان",
                    "irr" : "ریال",
                    "windows" : "ویندوز",
                    "ios" : "آی‌او‌اس",
            }, 
            currencyItems : {
                'sell' : {
                    "usdt" : "تتر",
                    "btc"  : "بیت کوین",
                    "eth"  : "اتریوم",
                    "doge" : "دوج",
                    "xrp"  : "ریپل",
                    "trx"  : "ترون",
                    "ltc"  : "لایت کوین",
                    "ada"  : "کاردانو",
                    "xlm"  : "استلار",
                    "bnb"  : "بایننس کوین",
                    "dash" : "دش",
                    "bch"  : "بیت کوین کش",
                }
            },
        }
    },
    mounted() {
        // دریافت اطلاعات تحلیل های تایید شده ی عمومی
        this.loadData();
    },
    computed: {
        // آواتار یک شاخص را پیدا می کند
        avatarFinder: function () {
            if (this.localCryptoExchangesProfile.supplier.image) {
                return  {
                    'position' : 'relative',
                    'background-image': 'url(https://platform.tgju.org/files/images/' + this.localCryptoExchangesProfile.supplier.image + ')',
                }
            } 
            return  {
                'position' : 'relative',
                'background-image': 'url(https://static.tgju.org/images/no-icon.png)',
            }
        },
    },
    methods: {
        to_yyyy_mm_dd(date) {
            return moment(date).format("H:m:s - jYYYY/jMM/jDD");
        },
        formatPrice(value) {
            if (value === null) {
                return '0';
            }

            value = parseFloat(value);
            return parseFloat(value.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        updateType(type) {
            this.type = type;
        },
        findCryptoName(item) {
            var result = item.grade.replace('-sell','').replace('-buy','');
            return this.cryptoName[result] ? this.cryptoName[result].fa : item.title;
        },
        finder(str) {
            if (str == null) {
                return '';
            }
            for (const [key, value] of Object.entries(this.supplier)) {
                str = str.replace(key, " " + value);
            }
            return str;
        },
        loadData() {
            this.loading = true;
            this.$helpers.makeRequest('POST', '/exchanges/local-crypto-profile', {
                slug: this.$route.params.slug
            }).then(api_response => {

                if ( api_response.status == 200 ) {
                    this.localCryptoExchangesProfile = api_response.data.response.detail.profile[this.$route.params.id];
                    this.cryptoName = api_response.data.response.detail.crypto_name;
                    this.loading = false;
                }
            });
        },
        // دکمه بازگشت
        back() {
            this.$router.back()
        },
    }
}
</script>